import React from 'react';

import { CountrySelectorPage } from '@components/CountrySelectorPage/CountrySelectorPage';
import { Paths } from '@utils/paths/paths';
import { PathsConfig } from '@utils/paths/paths-config';

import { getStory, getGlobalStories } from '@services/storyblok';

const pageId = Paths.CountrySelector;

export const CountrySelector = ({ story, pageContext }) => {
  return <CountrySelectorPage story={story} pageContext={pageContext} />;
};

export default CountrySelector;

export const getStaticProps = async context => {
  const { preview = false } = context;
  const storyblokSlug = PathsConfig[pageId].storyblokSlug;
  const { data } = await getStory({
    slug: storyblokSlug,
    preview: true,
  });
  const globalStories = await getGlobalStories({ preview: true });

  return {
    props: {
      story: data ? data.story : false,
      preview,
      pageContext: {
        globalStories,
        currentPageId: pageId,
      },
    },
    revalidate: 60,
  };
};
