import React, { useEffect } from 'react';

import { Image } from '@components/Image/Image';
import { getSitesVisitedCount } from '@hooks/gtm/usePageInitializationEvent';

import styles from './CountrySelectorPage.module.scss';
import { CountrySelector } from '../CountrySelector/CountrySelector';

import { sendPageInitializationEvent } from '@services/gtm/page-initialization';

export const CountrySelectorPage = ({ story, pageContext: { currentPageId } }) => {
  const { content } = story;

  useEffect(() => {
    sendPageInitializationEvent({
      language: '',
      pageCount: getSitesVisitedCount(),
      page: {
        type: currentPageId,
        url: '/',
      },
    });
  }, []);

  return (
    <div className={styles.countrySelectorComponent}>
      <Image
        image={content.backgroundImage}
        className={styles.backgroundImage}
        alt={content.backgroundImage.alt || 'Background Image'}
        backgroundImage
      />
      <div className={styles.container}>
        <div className={styles.topSection}>
          <Image
            image={content.logo}
            alt={content.logo?.alt || 'BlueMovement Logo'}
            className={styles.logo}
            style={{ height: 'auto' }}
          />
          <h1 className={styles.rent}>{content.title}</h1>
        </div>
        <div className={styles.bottomSection}>
          <h3 className={styles.please}>{content.instruction}</h3>
          <div className={styles.countrySelectorWrapper}>
            {content.options.map(option => (
              <CountrySelector option={option} key={option._uid} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
