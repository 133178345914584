import React from 'react';

import cn from 'classnames';
import NextImage from 'next/image';

import imagePlaceholder from '@assets/images/image-placeholder.jpg';

import styles from './Image.module.scss';

export const getImageSource = (image, qualityPercent) =>
  (image?.fieldtype === 'asset' &&
    image.filename &&
    `${image.filename}${
      image.filename.includes('.svg') ? '' : `/m/filters:format(webp):quality(${qualityPercent})`
    }`) ||
  (typeof image === 'string' && image);

export const Image = ({
  imgRef = null,
  image,
  mobileImage = '',
  alt = 'image',
  className = '',
  onClick = () => {},
  squareImage = false,
  backgroundImage = false,
  lazyLoading = true,
  style = {},
  width = 640,
  height = 640,
}) => {
  if (React.isValidElement(image)) {
    return image;
  }

  const imageSrc = getImageSource(image, 75) || imagePlaceholder;
  const mobileImageSrc = getImageSource(mobileImage, 75);

  const imageAlt = imageSrc?.alt || alt;

  return (
    <picture alt={alt} className={cn(className, { [styles.squareIcon]: squareImage })} onClick={onClick}>
      {mobileImageSrc && <source media="(max-width: 767px)" srcSet={mobileImageSrc} />}
      <source media="(min-width: 768px)" srcSet={imageSrc} />
      {/* width and height are REQUIRED by next/image if source is loaded from other domain. It's a placeholder before load and do not change image size */}
      <NextImage
        style={{ height: '100%', width: backgroundImage ? '100%' : 'auto', objectFit: 'cover', ...style }}
        width={width}
        height={height}
        ref={imgRef}
        src={imageSrc}
        alt={imageAlt}
        sizes="100vw"
        priority={!lazyLoading}
        loading={lazyLoading ? 'lazy' : 'eager'}
        fetchPriority={lazyLoading ? 'auto' : 'high'}
        quality={75}
        loader={({ src }) => src}
      />
    </picture>
  );
};
