import React from 'react';

import Link from 'next/link';

import styles from './CountrySelector.module.scss';

export const CountrySelector = ({ option }) => {
  const { country, languages } = option;

  return (
    <div className={styles.countrySelector}>
      <h2 className={styles.countryName}>{country}</h2>
      {languages.tbody.map(lang => (
        // eslint-disable-next-line no-underscore-dangle
        <Link className={styles.countryLanguage} key={lang._uid} href={`/${lang.body[1].value}`}>
          {lang.body[0].value}
        </Link>
      ))}
    </div>
  );
};
